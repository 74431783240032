import { createApp } from 'vue'
import axios from 'axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import App from './view/index/index'
import router from './router/index'

import store from '@/data/store';

// axios.defaults.baseURL = '/xiheServer/'
axios.defaults.baseURL = '/API/'

axios.defaults.headers['Content-Type'] = "application/x-www-form-urlencoded"

const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.use(store)

for(const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.mount('#app')